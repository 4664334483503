import React from 'react';
import { FaTelegram, FaTwitter, FaDiscord } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="social-icons">
          <a href="https://t.me/PulseVerse" target="_blank" rel="noopener noreferrer">
            <FaTelegram />
          </a>
          <a href="https://twitter.com/PulseVerseio" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://discord.com/invite/veUkyeVqPB" target="_blank" rel="noopener noreferrer">
            <FaDiscord />
          </a>
        </div>
        <p className="copyright">
          &copy; {new Date().getFullYear()} PulseVerse. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
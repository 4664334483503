import logo from './logo-wtrans.png';
import preview from './preview.png';
import './App.css';
import Footer from './Footer';

function App() {
  return (
    <div className="landing-page center">
      <header>
        <img src={logo} alt="Logo" />
      </header>
      <div class="main">
        <h1>Search for your Ethereum NFT Copies on PulseChain!</h1>
        <button onClick={() => window.open("https://search.pulseverse.io")}>FIND YOUR NFT COPIES!</button>
        <img src={preview} alt="Preview"/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
